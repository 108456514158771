import type { IItem } from '@Models/Item/item.model';
import type { IBailiffSlimItem } from '@Models/Item/bailiffSlimItem.model';
import type { IMargin } from '@Models/User/margin.model';
import type { IBidder } from '@Models/User/bidder.model';

import { defineStore } from 'pinia';
import {
  fetchItemsByCategory,
  fetchItemById,
  fetchBidderSlimItem,
  fetchMarginInfoService,
  fetchBidder,
  fetchUserContent,
  fetchItemPrice
} from '@Services/item.service';
import { useUserAuthStore } from '~coreStores/user-auth.store';
import { useMessages } from '@Composables/messages';

const { unidentifiedProblem } = useMessages();
const emptyMarginInfo: IMargin = {
  baseMargin: 0,
  comment: null,
  estimate: 0,
  loweredMarginStatus: '',
  margin: 0,
  marginDueDate: null,
  marginLeft: 0,
  marginPaid: false,
  notes: null,
  paymentSum: 0,
  paymentWay: null,
  payments: [],
  netPaymentExists: false,
  loweredMarginApplicationDate: '',
  loweredMarginMessageId: null
};
Object.freeze(emptyMarginInfo);

export const useItemStore = defineStore('item', {
  state: () => ({
    item: {
      id: 0,
      name: '',
      quantity: 0,
      city: '',
      institutionName: '',
      currentDate: '',
      dateCreated: '',
      startAuction: '',
      endAuction: '',
      marginDueDate: '',
      views: 0,
      attachments: [],
      attachmentsNote: null,
      htmlContent: '',
      estimate: 0,
      openingValue: 0,
      margin: 0,
      bidStep: 0,
      otherInThisAuction: [],
      itemCategory: {
        category: '',
        id: 0,
        key: 0,
        value: ''
      },
      auctionStatus: '',
      signature: '',
      closeInfo: {
        complaintJustified: false,
        itemPaymentRefused: false,
        knockDownAt: null,
        knockDownRefused: false,
        knockDownRefusedAt: null,
        knockedDown: false,
        knockedDownRefuse: false,
        knockedDownRefuseAt: null,
        noBidders: false,
        noBids: false,
        remitToCourtDate: null,
        waiverConfirmed: false
      },
      bailiffData: {
        addressData: {
          address: {
            buildingNo: '',
            city: '',
            community: null,
            country: '',
            district: '',
            flatNo: '',
            foreignAddress: false,
            postOffice: '',
            province: '',
            street: '',
            streetPrefix: '',
            zipCode: ''
          },
          institutionName: ''
        },
        bankIban: '',
        bankName: ''
      },
      canMakeEpayment: true,
      additionalParams: {},
      art864: true,
      description: '',
      notEventuated: true,
      auctionId: null,
      cancelReason: null,
      cancelAuctionAt: null,
      auctionCategory: '',
      auctionKind: '',
      protocolExists: true,
      publicDataSet: true,
      bailiff: false,
      ordinaryRealEstate: true,
      userContent: {
        bidderId: null,
        joinedIn: true,
        joinedInAt: null,
        marginPaid: null,
        marginPaidAt: null,
        canBid: true,
        canBidSince: null,
        documentProvision: null,
        myLastBid: null,
        myLastBidAt: null,
        maxBid: null,
        maxBidAt: null,
        maxBidderUuid: null,
        myBiddderUuid: null,
        winner: true,
        marginPaymentWay: null,
        itemPaymentWay: null,
        exclusionReason: null,
        exclusionComment: null,
        exclusionMessageId: null,
        hasPaidForItem: true,
        itemPaidAt: null,
        principalStatus: '',
        lowered: true,
        loweredSet: true,
        forfeit: true,
        canForfeit: true,
        waiverStatus: null,
        waiverDate: null,
        status: {},
        complaintJustified: true,
        complaintJudgeDecision: null,
        winnerFirstName: null,
        winnerMiddleName: null,
        winnerLastName: null,
        registryUnitStatus: null,
        resignationCount: null,
        canJoin: true,
        bidderMessageStatus: null,
        excluded: null,
        complaint: null,
        complaintValidated: null,
        registryUnitWorkflowStage: '',
        otherDocumentStatus: '',
        netPaymentForItemExists: false,
        netPaymentForItemSum: 0,
        paymentForItemDue: 0,
        exclusionAt: '',
        itemPaymentRefused: false,
        itemPaymentRefusedDate: null,
        itemPaymentRefuseMessageId: null
      },
      vat: false,
      vatRate: null,
      bailiffContent: null,
      systemFailure: null,
      overtimeAllowed: false,
      plannedEndAuction: '',
      projectLink: ''
    } as IItem,
    bailiffSlimItem: null as IBailiffSlimItem | null,
    marginInfo: emptyMarginInfo as IMargin,
    bidder: {
      propertyType: '',
      realEstateAdditionalInfo: {
        hasPesel: null,
        idDocIssuanceDate: null,
        idDocIssuerCountry: null,
        idDocSeriesNumber: null,
        idDocType: null,
        joinAsSpouse: false
      },
      userData: {
        address: {
          buildingNo: '',
          city: '',
          community: null,
          country: '',
          district: null,
          flatNo: '',
          postOffice: '',
          province: null,
          street: '',
          streetPrefix: null,
          zipCode: ''
        },
        email: '',
        id: null,
        pesel: '',
        identityDocumentIssuerCountry: ''
      }
    } as IBidder,
    auctionHasStarted: false as boolean,
    websocketBid: null as {
      deadline: string;
      extraDuration: number;
      counter: number;
    } | null,
    itemPrice: 0 as number,
    showWebsocketErrorModal: false as boolean
  }),
  actions: {
    async fetchItem(itemId: string) {
      return new Promise((resolve, reject) => {
        fetchItemById(itemId)
          .then((response) => {
            if (response == 412) {
              return reject(response);
            }
            this.item = response;
            return resolve(response);
          })
          .catch((error) => {
            if (error) {
              return reject(error);
            }
            return reject(unidentifiedProblem);
          });
      });
    },
    async fetchItemsList(category: string) {
      const response = await fetchItemsByCategory(category);
      return response;
    },
    async fetchBailiffSlimItem(itemId: string) {
      return new Promise((resolve, reject) => {
        fetchBidderSlimItem(itemId)
          .then((response) => {
            this.bailiffSlimItem = response;

            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            }

            reject(unidentifiedProblem);
          });
      });
    },
    async fetchMarginInfo(itemId: number): Promise<IMargin | unknown> {
      const userAuthStore = useUserAuthStore();

      if (!userAuthStore.getBidderUserData?.id) return;

      const response = await fetchMarginInfoService(userAuthStore.getBidderUserData.id, itemId);
      if (response) this.marginInfo = response as IMargin;
      return response;
    },
    async clearMarginInfo() {
      this.marginInfo = emptyMarginInfo;
    },
    async fetchBidderById(itemId: number): Promise<any> {
      const response = await fetchBidder(itemId);
      if (response) {
        this.bidder = { ...response };
      }
      return response;
    },
    removeWebsocketBid() {
      this.websocketBid = null;
    },
    changeAuctionHasStarted(data: boolean) {
      this.auctionHasStarted = data;
    },
    async fetchUserContent(itemId: number) {
      const response = await fetchUserContent(itemId);

      if (response) {
        this.item.auctionStatus = response.auctionStatus;
        this.item.userContent = response;
      }
      return response;
    },
    changeWebsocketBid(payload: any) {
      this.item.userContent.maxBid = payload.amount ? payload.amount : payload.bidAmount;
      this.item.userContent.maxBidAt = payload.bidDate;
      const winner =
        payload.bidderUuid === this.item.userContent.myBiddderUuid ||
        payload.registryUnitUuid === this.item.userContent.myBiddderUuid;
      this.item.userContent.winner = winner;
      if (winner) {
        this.item.userContent.myLastBid = payload.amount ? payload.amount : payload.bidAmount;
        this.item.userContent.myLastBidAt = payload.bidDate;
      }
      this.item.userContent.maxBidderUuid = payload.bidderUuid ? payload.bidderUuid : payload.registryUnitUuid;

      if (payload.overtime) {
        this.websocketBid = {
          deadline: payload.overtime.deadline,
          extraDuration: payload.overtime.extraDuration,
          counter: payload.overtime.counter
        };
      }
    },
    async fetchItemPrice(data: any): Promise<boolean | unknown> {
      const response = await fetchItemPrice(data.id);
      if (response) {
        this.itemPrice = response;
      }
      return response;
    },
    toggleWebsocketErrorModal(newShowWebsocketErrorModal: boolean) {
      this.showWebsocketErrorModal = newShowWebsocketErrorModal;
    }
  },
  getters: {
    getItem: (state) => {
      return state.item;
    },

    getItemPrice: (state) => {
      return state.item.userContent.maxBid || state.item.openingValue;
    },

    getBailiffSlimItem: (state) => {
      return state.bailiffSlimItem as IBailiffSlimItem | null;
    },

    getMarginInfo(): IMargin {
      return this.marginInfo;
    },

    getBidder(state): IBidder {
      return state.bidder;
    },
    getAuctionHasStarted(state): boolean {
      return state.auctionHasStarted;
    },
    getWebsocketBid(state) {
      return state.websocketBid;
    },
    getShowWebsocketErrorModal(state): boolean {
      return state.showWebsocketErrorModal;
    }
  }
});
