import type { IItem } from '@Models/Item/item.model';
import type { IBailiffSlimItem } from '@Models/Item/bailiffSlimItem.model';
import type { IAddressData } from '@Models/Bidder/addressData.model';
import type { IPrincipalDetail } from '@Models/PrincipalHistory/principalDetail.model';
import type { IMargin } from '@Models/User/margin.model';
import type { IGenericModel } from '@Models/generic.model';

import { useHttpService } from '@/composables/httpService';
import { useFetchService } from '@Composables/fetchService';

export function resignationFromJoiningAuction(itemId: number) {
  const { $lkBiddingFetch } = useNuxtApp();
  return $lkBiddingFetch<IGenericModel<any>>(`rest/bid/resign/${itemId}`, {
    method: 'PUT'
  });
}

export async function downloadProtocolForUser(itemId: string) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/protocol`;
  return await service.useGetFile(url);
}

export async function downloadProtocol(itemId: number) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/auction-protocol?itemId=${itemId}`;
  return await service.useGetFileWithErrorData(url);
}

export async function downloadHistoryXml(itemId: string) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/xml`;
  return await service.useGetFileWithErrorData(url);
}

export async function downloadHistoryPdf(itemId: string) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/xml-pdf?itemId=${itemId}`;
  return await service.useGetFileWithErrorData(url);
}

export async function fetchItemCaution(itemId: number) {
  const { get } = useFetchService();
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/caution`;
  return get(url);
}
export async function fetchItemById(itemId: string): Promise<IItem | any> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}`;
  return await service.useGet(url);
}

export async function fetchItemsByCategory(category: string): Promise<unknown> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/most-${category}/8`;
  return await service.useGet(url);
}

export async function fetchItemAddress(itemId: string): Promise<IAddressData> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/address`;
  return await service.useGet(url);
}

export async function fetchItemInspections(itemId: string): Promise<unknown> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/inspections`;
  return await service.useGet(url);
}

export async function fetchBidderSlimItem(id: string): Promise<IBailiffSlimItem | null> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/bailiff/item_data/${id}`;
  return await service.useGet(url);
}

export function fetchPrincipals(itemId: string): Promise<IPrincipalDetail[] | any> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/principal/info?itemId=${itemId}`;
  return service.useGet(url);
}

export function fetchMarginInfoService(userId: number, itemId: number): Promise<IMargin | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/margin-info?userId=${userId}&itemId=${itemId}`;
  return service.useGet(url);
}

export function fetchBidder(itemId: number): Promise<any> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/bidder/join-info?itemId=${itemId}`;
  return service.useGet(url);
}

export async function makeSeal(itemId: string): Promise<any | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/item/${itemId}/knock-down`;
  return await service.usePost(url, null);
}

export async function fetchCurrentServerTime() {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/timestamp`;
  return await service.useGet(url);
}

export function fetchUserContent(itemId: any) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.itemService}rest/item/${itemId}/user-content`;
  return service.useGet(url);
}

export function makeBid(params: any): Promise<any | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/bid`;
  return service.usePost(url, JSON.stringify(params));
}

export function fetchItemPrice(itemId: string) {
  const runtimeConfig = useRuntimeConfig();
  const service = useHttpService();

  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/item/win?itemId=${itemId}`;
  return service.useGet(url);
}

export async function refuseKnockDown(data: any): Promise<any | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/item/refuse-knock-down`;
  const service = useHttpService();
  return service.usePost(url, JSON.stringify(data));
}

export async function refuseKnockDownAfter(data: any): Promise<any | unknown> {
  const runtimeConfig = useRuntimeConfig();
  const url = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.biddingServer}/rest/bid/item/knocked-down-refuse`;
  const service = useHttpService();
  return service.usePost(url, JSON.stringify(data));
}
